.home-hero-container {
    height: 720px;
    overflow: hidden;
    width: 100vw;
}

.home-hero-section {
    min-height: 720px;
    padding-top: 135px;
    position: relative;
}

.background-image-description {
    display: none;
}

.home-hero-content {
    padding-left: 140px;
    padding-top: 20px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;

    p {
        max-width: 620px;
    }
}

#home-hero-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
    padding-right: 140px;
}

.hero-controls {
    // padding: 10px;
    width: 48px;
    height: 48px;
    position: relative;
    top: 36px;
    transform: translateY(-50%);
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.4901960784);
    background-image: linear-gradient(
        rgba(255, 255, 255, 0.1294117647),
        rgba(255, 255, 255, 0.1294117647)
    );
    border-radius: 12px;
    position: relative;
    box-shadow: 0 3px 14px 7px rgba(1, 0, 46, 0.0509803922);

    * {
        cursor: pointer;
    }

    button {
        background: none;
        border: none;
        width: 100%;
        height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.step-card-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 25px;
    width: 100%;
    padding-inline: 140px;
    position: absolute;
    // top: calc(100% - 200px);
    top: -200px;
    max-width: 1900px;
}

.journey-section {
    position: relative;
    padding: 220px 140px 60px 140px;
    background-color: #f7f9fc;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
}

.journey-item {
    display: grid;

    .content {
        h2 {
            font-size: 32px;
            line-height: 36px;
        }
    }

    &#matters {
        gap: 20px;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        height: 500px;

        .graphic {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 0px;
            width: 90%;
            height: 380px;
            position: relative;
            min-width: 270px;

            .overlay-img-small {
                width: 80%;
                height: 100%;
                // background-position: center;
                background-size: cover;
                border-radius: 12px;
            }
            .overlay-img-floating {
                width: 200px;
                position: absolute;
                right: 0;
                top: 100px;
            }
        }
        .content {
            position: relative;
            top: 50px;
        }
    }

    &.overlay-grid {
        &.right {
            grid-template-columns: 1.5fr 0.25fr 1fr;
            grid-template-rows: repeat(3, auto);
            height: 520px;

            .graphic {
                grid-column: 2 / 4;
                grid-row: 1 / 4;
            }

            .content {
                padding-bottom: 10px;

                grid-column: 1 / 3;
                grid-row: 2 / 2;
            }
        }

        .content {
            backdrop-filter: blur(10px);
            padding: 60px;
            border-radius: 16px;
            background-color: #f7f9fc1a;
            background-image: linear-gradient(
                144deg,
                #ffffff2e,
                #fff3 37%,
                #ffffff40 71%,
                #ffffff26
            );
            box-shadow: 0 9px 15px 4px #0000000d;
        }

        .graphic {
            background-size: cover;
            background-position: center;
            border-radius: 12px;
        }

        &.left {
            grid-template-columns: 1fr 0.25fr 1.5fr;
            grid-template-rows: repeat(3, auto);
            height: 520px;

            .graphic {
                grid-row: 1 / 4;
                grid-column: 1 / 3;
            }

            .content {
                grid-row: 2 / 2;
                padding-bottom: 10px;
                grid-column: 2 / 4;
            }
        }
    }

    .double-ul {
        padding-bottom: 10px;
        display: flex;
        flex-direction: row;

        ul {
            color: var(--dark-blue);
            margin-block: 5px;
            padding-left: 40px;
            line-height: 28px;
        }
    }

    .why-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .why-item {
            font-size: 14px;
            img {
                width: 75px;
                height: 75px;
            }
        }
    }
}

a.orange {
    text-decoration: none;
    color: var(--orange);
    font-weight: 700;
}

a.orange:hover {
    color: #32343a;
}

.faq-section {
    position: relative;
    // z-index: -1;
    padding: 100px 140px 60px 140px;
    background-color: #f7f9fc;

    .bg-section {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 600px;

        .overlay-color {
            background-image: linear-gradient(#0800a233, #0800a233),
                linear-gradient(#0006, #0006);
            position: relative;
            position: absolute;
            inset: 0;
        }

        img {
            position: relative;
            width: 100%;
            object-position: center;
            object-fit: cover;
            height: 100%;
        }
    }

    .top-content {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
        gap: 20px;
        margin-bottom: 60px;

        h2,
        p {
            color: white;
        }
    }
}

@media (max-width: 990px) {
    .home-hero-content {
        padding-left: 60px;
    }

    .step-card-row {
        padding-inline: 60px;
    }

    #home-hero-top {
        padding-right: 60px;
    }

    .journey-section {
        padding-inline: 60px;
    }

    .faq-section {
        padding: 60px;
    }
}

@media (max-width: 770px) {
    .home-hero-content {
        width: 100%;
    }

    .step-card-row {
        padding-inline: 20px;
    }

    #home-hero-top {
        padding-right: 20px;
    }

    .journey-section {
        padding-inline: 20px;
        gap: 80px;
    }

    .faq-section {
        padding-inline: 20px;
    }
}

@media (max-width: 610px) {
    #home-hero-top {
        padding-right: 0px;
    }

    .home-hero-content {
        padding-inline: 20px;
    }

    .step-card-row {
        grid-template-columns: 1fr;

        // top: 100%;
        // padding-top: 20px;
        position: static;
        padding-inline: 0;
    }

    .vertical-text-slide-wrapper {
        margin-bottom: 60px;
        align-self: flex-start;

        .options-container {
            top: 100%;
            left: 0;
        }
    }

    .journey-section {
        padding: 20px 0 0 0;
        align-items: center;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-inline: 20px;
        gap: 20px;

        .journey-item#matters {
            margin-bottom: 100px;
            display: block;
            height: max-content;

            .graphic {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 0px;
                width: 100%;
                height: 380px;
                position: relative;
                min-width: 270px;

                .overlay-img-floating {
                    width: 200px;
                    position: absolute;
                    right: 0;
                    top: 150px;
                }
            }

            .content {
                margin-top: 100px;
            }
        }

        .journey-item.overlay-grid {
            display: block;
            height: auto;
            .graphic {
                height: 300px;
                position: relative;
                top: -50px;
            }

            .content {
                padding: 25px;
                position: relative;
                z-index: 2;

                .double-ul {
                    flex-direction: column;
                    margin-block: 5px;

                    ul {
                        margin-block: 0;
                    }
                }
            }
        }
    }
}

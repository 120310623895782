#caccf-page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0px;
    width: 100%;

    .caccf-top-section {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 400px;
        min-height: max-content;
        margin-bottom: 100px;

        .caccf-top-inner {
            padding: 30px 50px;
            width: 100%;
            .caccf-top-flex {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                gap: 20px;
            }
        }

        .content {
            padding-left: 80px;
            padding-top: 20px;
            h1,
            p {
                color: white;
            }
        }
    }

    .caccf-top-container {
        height: 100%;
        width: 100vw;
        overflow: hidden;
    }

    .steps-section {
        text-align: center;
        .steps-container {
            padding-block: 40px 60px;
            display: grid;
            gap: 80px;
            grid-template-columns: repeat(3, 1fr);

            .caccf-step {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                img {
                    width: 72px;
                    height: 72px;
                }
            }
        }
    }

    .resources-section {
        width: 100%;
        text-align: center;
        .resource-links-container {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            padding-top: 20px;
            height: max-content;
            gap: 16px;
        }
    }

    #caccf-login {
        margin-left: 10px;
    }

    @media (max-width: 990px) {
        #caccf-login {
            margin-left: 0px;
            margin-top: 10px;
        }

        .caccf-top-section {
            .content {
                padding-left: 0;
            }
        }

        .outline-button,
        .cta-button {
            width: 100%;
            text-align: center;
        }

        .steps-section .steps-container,
        .resources-section .resource-links-container {
            grid-template-columns: 1fr;
        }
    }
}

.outline-button {
    border: 1px solid var(--orange);
    text-align: center;

    border-radius: 8px;
    text-decoration: none;
    color: var(--orange);
    font-weight: 700;
    padding: 12px 15px;
    display: inline-block;

    &.white {
        border-color: white;
        color: white;

        &:hover {
            border-color: #ddd;
            color: #ddd;
        }
    }

    &:hover {
        color: #b13700;
        border-color: #b13700;
    }

    &.big {
        border-radius: 16px;
        padding: 25px 15px;
        // width: 100%;
    }
}

.vertical-text-slide-wrapper {
    // display: flex;
    // flex-direction: row;
    // gap: 10px;
    position: relative;
    width: fit-content;

    span {
        color: white;
        font-size: 60px;
        font-weight: 300;
    }

    .options-container {
        position: absolute;
        top: 0;
        left: calc(100% + 8px);

        span {
            opacity: 0;
            color: #ff6200;
            border-radius: 16px;
            display: block;
            animation: topToBottom 12.5s linear infinite 0s;
            position: absolute;

            @for $i from 1 through 5 {
                &:nth-child(#{$i}) {
                    animation-delay: #{($i - 1) * 2.5}s;
                }
            }
        }
    }
}

@keyframes topToBottom {
    0% {
        opacity: 0;
    }

    5% {
        opacity: 0;
        transform: translateY(-50px);
    }

    10% {
        opacity: 1;
        transform: translateY(0px);
    }

    25% {
        opacity: 1;
        transform: translateY(0px);
    }

    30% {
        opacity: 0;
        transform: translateY(50px);
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}
